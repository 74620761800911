<template>
  <div>
    <v-toolbar>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark text color="primary">People per page: {{ itemsPerPage }} <v-icon small right>fal fa-chevron-down</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item v-for="opt in itemsPerPageOptions" :key="opt" @click="setItemsPerPage(opt)">
            <v-list-item-title>{{ opt }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <span class="grey--text">
        Showing {{ ((page - 1) * itemsPerPage) + 1 }} - {{ (page * itemsPerPage) > total ? total : page * itemsPerPage }} of {{ total }}
      </span>
      <template v-if="!isXSmall">
        <v-spacer></v-spacer>
        <v-btn :disabled="page === 1" icon @click="page--">
          <v-icon>fal fa-chevron-left</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" dark text color="primary">Page {{ page }} / {{ pageCount }}</v-btn>
          </template>
          <v-list style="max-height:400px;overflow:auto">
            <v-list-item v-for="n in pageCount" :key="'page-' + n" @click="page = n">
              <v-list-item-title>Page {{ n }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn :disabled="page === pageCount" icon @click="page++">
          <v-icon>fal fa-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <v-toolbar v-if="isXSmall">
      <v-btn :disabled="page === 1" icon @click="page--">
        <v-icon>fal fa-chevron-left</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark text color="primary">Page {{ page }} / {{ pageCount }}</v-btn>
        </template>
        <v-list style="max-height:400px;overflow:auto">
          <v-list-item v-for="n in pageCount" :key="'page-' + n" @click="page = n">
            <v-list-item-title>Page {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn :disabled="page === pageCount" icon @click="page++">
        <v-icon>fal fa-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    total: Number
  },
  setup (props, { root, emit }) {
    const view = computed(() => { return root.$store.state.greatscots.listView })
    const page = computed({
      get: () => {
        return root.$store.state.greatscots.resultOptions.page || 1
      },
      set: (value) => {
        root.$store.commit('greatscots/setResultOption', { field: 'page', value })
        emit('reload')
      }
    })
    const itemsPerPage = computed({
      get: () => {
        return root.$store.state.greatscots.resultOptions.itemsPerPage || 10
      },
      set: (value) => {
        root.$store.commit('greatscots/setResultOption', { field: 'itemsPerPage', value })
        emit('reload')
      }
    })
    const pageCount = computed(() => {
      return Math.ceil(props.total / itemsPerPage.value)
    })
    const itemsPerPageOptions = computed(() => {
      // if (view.value === 'list') return [ 5, 10, 15, 25, 40, 50 ]
      // else
      return [ 6, 12, 18, 24, 30, 36 ]
    })
    function setItemsPerPage (value) {
      itemsPerPage.value = value
    }

    const isXSmall = computed(() => root.$vuetify.breakpoint.name === 'xs')

    return {
      view,
      page,
      pageCount,
      itemsPerPage,
      itemsPerPageOptions,
      setItemsPerPage,
      isXSmall
    }
  }
}
</script>
